import * as styledComponents from 'styled-components';
import { ThemeType } from './theme';

// TODO: There is a new way to override the default theme
// https://styled-components.com/docs/api#create-a-declarations-file
// Did not seem to work (maybe typescript version too old?)

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeType>;

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };
export default styled;
