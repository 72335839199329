import { ChatCircle, PaperPlaneTilt } from 'phosphor-react';
import styled, { css } from '../helpers/styled';
import { smallQuery } from '../helpers/sizeClass';
import React from 'react';

export const Grid = styled.div`
  display: grid;
`;

export const BaseGrid = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const GridVertical2 = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  @media ${smallQuery} {
    grid-template-columns: auto;
    justify-items: center;
  }
`;

export const GridVertical3 = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 24px;
`;

export const SimpleButton = styled.button`
  padding: 10px;
  white-space: nowrap;
`;

export interface TextProps {
  medium?: boolean;
  accent?: boolean;
  light?: boolean;
  center?: boolean;
}

const BaseText = css<TextProps>`
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  color: ${(props) =>
    props.accent
      ? props.theme.textAccent
      : props.medium
      ? props.theme.textMedium
      : props.light
      ? props.theme.textLight
      : props.theme.textDark};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const GradientHeading = styled.h1`
  ${BaseText};
  font-weight: 700;
  font-size: 64px;
  @media ${smallQuery} {
    font-size: 36px;
  }
  background: linear-gradient(150deg, #a2d6f4 15.37%, #89e99e 85.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const Heading1 = styled.h1`
  ${BaseText};
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;

  @media ${smallQuery} {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Heading2 = styled.h2`
  ${BaseText};
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  @media ${smallQuery} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Heading2Bold = styled(Heading2)`
  font-weight: 700;
`;

export const Heading3 = styled.h3`
  ${BaseText};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  @media ${smallQuery} {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const TextBold = styled.p`
  ${BaseText};
  font-weight: 700;
  font-size: 20px;
`;

export const TextB = styled.p`
  ${BaseText};
  font-weight: 400;
  font-size: 20px;
`;

//TODO created mobile button text classes instead of using this
export const TextM = styled.p`
  ${BaseText};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media ${smallQuery} {
    font-size: 10px;
    line-height: 18px;
  }
`;

export const TextMBold = styled.p`
  ${BaseText};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const TextS = styled.p`
  ${BaseText};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const TextSBold = styled.p`
  ${BaseText};
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;

export const ALink = styled.a`
  color: ${(props) => props.theme.textAccent};
  font-size: 16px;
`;

export const ClearButton = styled.button<{
  size?: number;
  hoverColors?: boolean;
  selected?: boolean;
}>`
  ${BaseText};
  background: transparent;
  border: none;
  color: ${(props) => props.theme.textMedium};
  ${(props) => props.onClick && `cursor: pointer;`}
  ${(props) =>
    props.size &&
    `height: ${props.size}px;
    width: ${props.size}px;`}
  position: relative;
  ${(props) =>
    props.hoverColors &&
    `
   &:hover {
    background: ${props.theme.backgroundButtonHover};
  }`}
`;

export const ClearButtonText = styled(TextS)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
interface BaseButtonProps {
  readonly selected?: boolean;
  readonly disableShadow?: boolean;
  readonly disabled?: boolean;
}

const BaseButton = css<BaseButtonProps>`
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  background-blend-mode: multiply;
  box-shadow: ${(props) =>
    props.disableShadow ? 'none;' : '0px 4px 4px rgba(28, 83, 153, 0.25); '};
  border: none;
  border-radius: 3px;
  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background: ${props.theme.backgroundButtonHover};
  }
  `}
  padding: 10px 15px;
  white-space: nowrap;
`;

export const GradientBackgroundCard = styled.div`
  background: ${(props) => props.theme.primaryButtonBackground};
  padding: 40px;
`;

export const InfoBackgroundCard = styled.div`
  background: ${(props) => props.theme.infoColor};
  background-blend-mode: multiply;
  border-radius: 10px;
  padding: 32px;
`;

export const PrimaryButton = styled.button`
  ${BaseButton}
  background: ${(props) => {
    let color = props.selected ? '#fff' : props.theme.gradient4;

    color = props.disabled
      ? props.theme.primaryButtonBackgroundDisabled
      : color;
    return color;
  }};
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};
  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background: ${props.theme.gradient3};
  }`}
`;
//TODO disable hover on disabled
export const UiButton = styled.button`
  ${BaseButton}
  background: ${(props) => {
    let color = props.selected ? '#fff' : props.theme.gradient3;

    color = props.disabled
      ? props.theme.primaryButtonBackgroundDisabled
      : color;
    return color;
  }};
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};

  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background: ${props.theme.gradient4};
  }
  `}
`;

export const CircleUiButton = styled(UiButton)`
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
  width: 126px;
  height: 126px;
  white-space: normal;
`;

export const InfoButton = styled.button`
  ${BaseButton}
  height: 56px;
  background: ${(props) => {
    let color = props.selected ? '#fff' : props.theme.infoColor;

    color = props.disabled
      ? props.theme.primaryButtonBackgroundDisabled
      : color;
    return color;
  }};
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};

  &:hover {
    background: ${(props) => props.theme.gradient3};
  }
`;

export const CompleteWorkshopButton = styled.button`
  ${BaseButton}
  width: 247px;
  height: 56px;
  background: ${(props) =>
    props.disabled
      ? '#fff'
      : `linear-gradient(149.68deg, #DFE4FF 25.39%, #CDD5FF 84%);`};
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};
`;

export const ReadingButtonInner = styled(PrimaryButton)<{ active?: boolean }>`
  display: grid;
  width: 486px;
  height: 78px;
  column-gap: 19px;
  align-items: center;
  justify-items: start;
  box-shadow: 0px 15px 50px rgba(28, 83, 153, 0.25);
  grid-template-columns: min-content 296px min-content min-content;
  ${(props) => {
    if (!props.active) {
      return `background: #fff;`;
    }
  }};
`;

export const SurveyButton = styled.button`
  ${BaseButton}
  background: ${(props) => {
    let color = !props.selected ? '#fff' : props.theme.primaryButtonBackground;

    color = props.disabled
      ? props.theme.primaryButtonBackgroundDisabled
      : color;
    return color;
  }};
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};
  box-shadow: 0px 15px 70px rgba(28, 83, 153, 0.25);
  border-radius: 4px;
`;

export const GridFiller = styled.div<{ height?: number }>`
  height: ${(props) => (props.height ? props.height + 'px' : '12px')};
`;
//TODO selected style for secondary button
export const SecondaryButton = styled.button`
  ${BaseButton}
  background: ${(props) => props.theme.background};
  border-radius: 2px;
  color: ${(props) =>
    props.disabled ? props.theme.textLight : props.theme.textDark};
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(150deg, #e1f4ff 25.39%, #dbffe3 84%);
  &:hover {
    border: 2px solid transparent;
  }
`;

export const TextWithCursor = styled(TextM)`
  cursor: pointer;
`;

const TooltipText = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 999;
  left: 105%;
  bottom: 100%;
  background: #5173ec;
  border-radius: 20px;
  color: #fff;
  min-width: 113px;
  padding: 8px 16px 8px 16px;
  &::after {
    content: ' ';
    position: absolute;
    bottom: -2px; /* At the bottom of the tooltip */
    left: -2px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-right: 10px solid #5173ec;
    transform: rotate(-45deg);
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
  &:hover {
    ${TooltipText} {
      visibility: visible;
    }
  }
`;

export const Tooltip = (props: {
  children: JSX.Element | string;
  tooltipText: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <TooltipWrapper className={props.className}>
      {props.children}
      {props.disabled !== true && (
        <TooltipText>{props.tooltipText}</TooltipText>
      )}
    </TooltipWrapper>
  );
};

export const IconButton = (props: {
  children: JSX.Element | string;
  onClick?: () => void;
  takeFullHeight?: boolean;
  className?: string;
  text?: string;
  position?: 'center' | 'topRight';
  size?: number;
  hoverColors?: boolean;
  selected?: boolean;
}) => {
  return (
    <ClearButton
      className={props.className}
      onClick={props.onClick}
      size={props.size}
      hoverColors={props.hoverColors}
      selected={props.selected}
    >
      {props.children}
      {props.text && <ClearButtonText>{props.text}</ClearButtonText>}
    </ClearButton>
  );
};

const NoteInputContainer = styled.div`
  position: relative;
`;

const NoteInput = styled.input`
  border: 1px solid #798b9d;
  border-radius: 10px;
  min-height: 56px;
  width: 100%;
  margin-top: 32px;
  padding-left: 16px;
`;

export const NoteSendButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  bottom: 12px;
`;

export const TextInputWithSend = (props: {
  placeholderText: string;
  value?: string;
  onChange?: (str: string) => void;
  onSend?: () => void;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event.target.value);
  };

  return (
    <NoteInputContainer>
      <NoteInput
        type="Text"
        placeholder={props.placeholderText}
        value={props.value}
        onChange={handleChange}
      ></NoteInput>
      {props.onSend && (
        <NoteSendButton onClick={props.onSend}>
          <PaperPlaneTilt size={24} />
        </NoteSendButton>
      )}
    </NoteInputContainer>
  );
};

export const ChatCount = (props: { count: number; size?: number }) => {
  return (
    <IconButton
      text={props.count.toString()}
      size={props.size ? props.size : 32}
    >
      <ChatCircle size={props.size ? props.size : 32}></ChatCircle>
    </IconButton>
  );
};

export const PageButtonHeader = styled.div<{}>`
  display: grid;
  text-align: left;
  grid-template-columns: auto min-content;
`;

export const ScoreCircle = styled.div<{ score?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;

  width: 48px;
  height: 48px;

  background: #b9bed8;
  ${(props) => {
    if (props.score) {
      let color =
        props.score <= 33
          ? props.theme.result5
          : props.score <= 66
          ? props.theme.result3
          : props.theme.result1;
      return `
      background: ${color};
      background-blend-mode: multiply;
      `;
    }
  }}

  border-radius: 50px;
`;
