import { useState, useEffect } from 'react';
import styled from './styled';

export const enum SizeClasses {
  Small = 'small',
  Medium = 'medium',
  Big = 'big',
}

export const smallQuery = '(max-width: 700px)';
export const smallInvertQuery = '(min-width: 701px)';
export const bigQuery = '(min-width: 1200px)';
export const bigInvertQuery = '(min-width: 1199px)';
export const mediumQuery = '(min-width: 701px) and (max-width: 1199px)';
export const mediumSmallQuery = '(min-width: 701px) and (max-width: 950px)';
export const mediumSmallUnderQuery = '(max-width: 950px)';

export const useSize = () => {
  const [size, setSize] = useState(SizeClasses.Big);

  const detectSize = (isSmall: MediaQueryList, isBig: MediaQueryList) => {
    if (isSmall.matches) {
      setSize(SizeClasses.Small);
    } else if (isBig.matches) {
      setSize(SizeClasses.Big);
    } else {
      setSize(SizeClasses.Medium);
    }
  };

  useEffect(() => {
    const isSmall = window.matchMedia(smallQuery);
    const isBig = window.matchMedia(bigQuery);
    detectSize(isSmall, isBig);
    const listener = () => detectSize(isSmall, isBig);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [size]);

  return size;
};

// Show on small size
export const ShowSmall = styled.div<{}>`
  @media ${smallInvertQuery} {
    display: none;
  }
`;

// Show on all non small sizes
export const ShowNormal = styled.div<{}>`
  @media ${smallQuery} {
    display: none;
  }
`;
