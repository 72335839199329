import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GenericLoading } from './components/cardComponents';

const Survey = React.lazy(() => import('./pages/survey2'));
const App = React.lazy(() => import('./App'));

function Split() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/survey">
            <React.Suspense fallback={<GenericLoading />}>
              <Survey />
            </React.Suspense>
          </Route>
          <Route path="*">
            <React.Suspense fallback={<GenericLoading />}>
              <App />
            </React.Suspense>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Split;
