import angleToCoordinates from 'css-gradient-angle-to-svg-gradient-coordinates';
import styled, { useTheme } from '../helpers/styled';
import { TextM } from './leapTo';
const coordinates = angleToCoordinates(150);

export enum SvgButtonStyle {
  GradientGreen,
  BigGreen,
  InvertedGreen,
  GreyGreen,
  SolidGreen,
  SolidBlue,
}

function isSolid(style: SvgButtonStyle) {
  return style !== SvgButtonStyle.GradientGreen;
}

interface StarSvgProps {
  readonly buttonStyle: SvgButtonStyle;
  readonly selected: boolean;
  readonly noShadow: boolean;
  readonly enableClick: boolean;
}

const StarSvg = styled.svg<StarSvgProps>`
  grid-column: 1/1;
  grid-row: 1/1;
  transition-duration: 0.4s;
  cursor: ${(props) => (props.enableClick ? 'pointer' : 'default')};
  fill: ${(props) => {
    if (props.buttonStyle === SvgButtonStyle.SolidBlue) {
      return props.theme.svgSolidBlue;
    } else if (props.buttonStyle === SvgButtonStyle.SolidGreen) {
      return props.theme.svgSolidGree;
    } else if (props.buttonStyle === SvgButtonStyle.InvertedGreen) {
      return props.selected
        ? 'url(#InvertedGreenGradient)'
        : props.theme.svgSolidBlue;
    } else if (props.buttonStyle === SvgButtonStyle.GreyGreen) {
      return props.selected ? 'url(#GreyGradient)' : props.theme.svgGrey;
    }
    return props.selected
      ? 'url(#GreenGradient)'
      : props.theme.svgGreenUnselected;
  }};
  filter: ${(props) =>
    props.noShadow
      ? 'none'
      : props.buttonStyle === SvgButtonStyle.BigGreen
      ? props.theme.svgBigGreenShadow
      : props.selected
      ? props.theme.svgSelectedShadow
      : props.theme.svgShadow};
  &:hover {
    ${(props) => {
      let fill: string | undefined = undefined;
      if (props.enableClick) {
        if (
          props.buttonStyle === SvgButtonStyle.GradientGreen ||
          props.buttonStyle === SvgButtonStyle.InvertedGreen
        ) {
          fill = props.theme.svgGreenHover;
        } else if (props.buttonStyle === SvgButtonStyle.GreyGreen) {
          fill = props.selected ? props.theme.svgGrey : 'url(#GreyGradient)';
        }
      }
      if (fill) {
        return `fill: ${fill};`;
      }
      return ``;
    }}

    ${(props) =>
      props.enableClick &&
      `
      filter: ${() =>
        props.noShadow ? 'none' : props.theme.svgSelectedShadow};
    `}
  }
`;
const SvgButtonContainer = styled.div<{
  stretch: boolean;
  justifyContent?: string;
}>`
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: ${(props) =>
    props.stretch
      ? 'stretch'
      : props.justifyContent
      ? props.justifyContent
      : 'end'};
`;

const SvgButtonText = styled(TextM)<{
  solid: boolean;
  stretch: boolean;
  enableClick: boolean;
}>`
  text-align: center;
  width: ${(props) => (props.stretch ? 'fit-content' : 'min-content')};
  grid-column: 1/1;
  grid-row: 1/1;
  padding: 0px 15%;
  ${(props) => props.enableClick && `pointer-events: none;`}
  color: ${(props) =>
    props.solid ? props.theme.svgSolidText : props.theme.textDark};
`;

const SvgButtonContent = styled.div<{
  solid: boolean;
  stretch: boolean;
  enableClick: boolean;
}>`
  text-align: center;
  width: ${(props) => (props.stretch ? 'fit-content' : 'min-content')};
  grid-column: 1/1;
  grid-row: 1/1;
  padding: 0px 15%;
  ${(props) => props.enableClick && `pointer-events: none;`}
  color: ${(props) =>
    props.solid ? props.theme.svgSolidText : props.theme.textDark};
`;

export const SvgButton = (props: {
  type: 'star' | 'circle';
  buttonStyle?: SvgButtonStyle;
  text?: string;
  children?: JSX.Element;
  size?: number;
  stretch?: boolean;
  selected?: boolean;
  disableShadow?: boolean;
  bigText?: boolean;
  solid?: boolean;
  onClick?: () => void;
  className?: string;
  justifyContent?: string;
}) => {
  const theme = useTheme();
  const buttonStyle = props.buttonStyle || SvgButtonStyle.GradientGreen;
  const path =
    props.type === 'star' ? (
      <path d="M96 0L137.145 43.3688L191.106 69.0983L162.574 121.631L154.779 180.902L96 170L37.2215 180.902L29.426 121.631L0.894348 69.0983L54.855 43.3688L96 0Z" />
    ) : (
      <circle cx="50" cy="50" r="50" />
    );

  return (
    <SvgButtonContainer
      className={props.className}
      stretch={props.stretch || false}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick?.();
      }}
      justifyContent={props.justifyContent}
    >
      <StarSvg
        buttonStyle={buttonStyle}
        noShadow={props.disableShadow || false}
        width={props.size ? props.size + 'px' : '100%'}
        height={props.size ? props.size + 'px' : '100%'}
        viewBox={props.type === 'star' ? '0 0 192 181' : '0 0 100 100'}
        xmlns="http://www.w3.org/2000/svg"
        selected={props.selected || false}
        enableClick={props.onClick !== undefined}
      >
        <defs>
          <linearGradient
            id="GreenGradient"
            x1={coordinates.x1}
            y1={coordinates.y1}
            x2={coordinates.x2}
            y2={coordinates.y2}
          >
            <stop offset="25%" stopColor={theme.svgGreenGradientStart} />
            <stop offset="85%" stopColor={theme.svgGreenGradientEnd} />
          </linearGradient>
          <linearGradient
            id="InvertedGreenGradient"
            x1={coordinates.x1}
            y1={coordinates.y1}
            x2={coordinates.x2}
            y2={coordinates.y2}
          >
            <stop
              offset="25%"
              stopColor={theme.svgGreenInvertedGradientStart}
            />
            <stop offset="85%" stopColor={theme.svgGreenInvertedGradientEnd} />
          </linearGradient>
          <linearGradient
            id="GreyGradient"
            x1={coordinates.x1}
            y1={coordinates.y1}
            x2={coordinates.x2}
            y2={coordinates.y2}
          >
            <stop offset="25%" stopColor={theme.svgGreySelectedStart} />
            <stop offset="85%" stopColor={theme.svgGreySelectedEnd} />
          </linearGradient>
        </defs>
        {path}
      </StarSvg>

      {props.text ? (
        <SvgButtonText
          stretch={props.stretch || false}
          solid={props.solid !== undefined ? props.solid : isSolid(buttonStyle)}
          enableClick={props.onClick !== undefined}
        >
          {props.text}
        </SvgButtonText>
      ) : props.children ? (
        <SvgButtonContent
          stretch={props.stretch || false}
          solid={isSolid(buttonStyle)}
          enableClick={props.onClick !== undefined}
        >
          {props.children}
        </SvgButtonContent>
      ) : null}
    </SvgButtonContainer>
  );
};

export const SvgButtonContentGrid = styled.div`
  display: grid;
  grid-row-gap: 24px;
`;
