import { useState } from 'react';
import { adminStoreInstance } from './stores/adminStore';
export const _fetch = async (request: RequestInfo, init?: RequestInit) => {
  try {
    const response = await fetch(request, init);
    const status = response.status;
    if (199 < status && status < 300) {
      //success
      const result = await response.json();
      console.log(result)
      return result;
    } else {
      const error = await response.json();
      console.log(error);
      if (error) {
        throw new Error(error.error);
      } else {
        throw new Error(`Status ${status}. Info: ${response.statusText}`);
      }
    }
  } catch (error) {
    throw error;
  }
};

// Hook
export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      const retVal = item !== null ? JSON.parse(item) : initialValue;
      return retVal;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
};

export function readableDate(str: string) {
  return new Date(str).toDateString();
}
//TODO possibile to fail?
export function shortDate(str: string) {
  return readableDate(str).split(' ').slice(1).join(' ');
}

export function readableDateWithClock(str: string) {
  return (
    new Date(str)
      .toTimeString()
      .split(' ')[0]
      .split(':')
      .slice(0, 2)
      .join(':') +
    ' ' +
    readableDate(str)
  );
}

export const getCurrentActionRef = (store: adminStoreInstance) => {
  const ref = store.teams.selectedTeam.implementationRef;
  if (!ref) {
    return '';
  }
  return store.implementations.getImplementation(ref)!.taskCard.actionRef;
};

export const ParseNewLines = (text: string): string[] => {
  return text.split('\n');
};

export const parseWorkshopDuration = (durationMins: number): string => {
  //change minutes to hours
  const hours = Math.floor(durationMins / 60);
  const minutes = durationMins % 60;
  if (hours <= 0) {
    const retVal: string = `${minutes}min`;
    return retVal;
  } else {
    const retVal: string = `${hours}h ${minutes}min`;
    return retVal;
  }
};

export const getUserName = (
  id: string | undefined,
  store: adminStoreInstance
) => {
  if (id) {
    const user = store.teams.selectedTeam?.members.data.find(
      (user) => user.userId === id
    );
    if (user) {
      if (user.userId === store.userId) {
        return 'You';
      } else {
        const position = user.email.search('@');
        const name = user.email.slice(0, position);
        return name;
      }
    }
  }
  return 'Unknown author';
};
