import { ChatCircle, ThumbsUp } from 'phosphor-react';
import { useState } from 'react';
import styled, { useTheme } from '../helpers/styled';
import {
  Heading1,
  Heading2,
  IconButton,
  TextM,
  PrimaryButton,
  TextB,
  TextS,
} from './leapTo';
import { mediumSmallQuery, smallQuery } from '../helpers/sizeClass';
import { SvgButton, SvgButtonStyle } from './buttonSvg';
import React from 'react';
import { readableDate } from '../helpers';
import { ClockCounterClockwise, Play, CircleNotch } from 'phosphor-react';
import { VoteLikeItem } from '@backend/types';
import { CircleButton } from './circleButton';

export const CardRoot = styled.div<{ transparent?: boolean }>`
  display: grid;
  box-shadow: 0px 15px 70px rgba(28, 83, 153, 0.25);
  border-radius: 4px;
  background: ${(props) =>
    props.transparent ? 'transparent' : props.theme.background};
`;

export const CardHeader = styled.div`
  display: grid;
  padding: 16px 32px;
`;

export const LeftRightIconCardHeader = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  padding: 16px 32px;
  align-items: center;
  grid-column-gap: 24px;
`;

function gridRowPosition(index: number, numberOfColumns: number) {
  return Math.floor(index / numberOfColumns) * 2 + 1;
}

function gridColPosition(index: number, numberOfColumns: number) {
  return (index % numberOfColumns) + 1;
}

export const GradientHeader = styled.div`
  display: grid;
  margin-top: 24px;
  background: linear-gradient(150deg, #e1f4ff 25.39%, #f0f0f0 84%);
  padding: 16px 16px 0px 16px;
  grid-template-rows: auto min-content;
  border-radius: 4px 4px 0px 0px;
`;

export const PositionedGradientHeader = styled(GradientHeader)<{
  index: number;
}>`
  grid-column: ${(props) => gridColPosition(props.index, 3)};
  grid-row: ${(props) => gridRowPosition(props.index, 3)};
  @media ${mediumSmallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 2)};
    grid-row: ${(props) => gridRowPosition(props.index, 2)};
  }
  @media ${smallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 1)};
    grid-row: ${(props) => gridRowPosition(props.index, 1)};
  }
`;

export const GradientHeaderContent = styled.div`
  display: grid;
  grid-row-gap: 24px;
  grid-template-rows: min-content min-content auto;
`;

export const VoteCardBoxShadow = styled.div<{ index: number }>`
  box-shadow: 0px 15px 70px rgba(28, 83, 153, 0.25);
  z-index: 50;
  margin-top: 24px;
  border-radius: 0px 0px 4px 4px;
  grid-column: ${(props) => gridColPosition(props.index, 3)};
  grid-row: ${(props) => gridRowPosition(props.index, 3)} / span 2;
  @media ${mediumSmallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 2)};
    grid-row: ${(props) => gridRowPosition(props.index, 2)} / span 2;
  }
  @media ${smallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 1)};
    grid-row: ${(props) => gridRowPosition(props.index, 1)} / span 2;
  }
`;

export const CardContentRoot = styled.div`
  display: grid;
  grid-row-gap: 24px;
  justify-items: center;
  padding: 16px;
`;

export const VoteCardContentWrapper = styled.div<{
  index: number;
  showLabel?: boolean;
}>`
  display: grid;
  grid-template-rows: auto min-content min-content;
  grid-row-gap: 24px;
  justify-items: center;
  padding: 16px;
  margin-bottom: ${(props) => (props.showLabel ? '32px' : '0px')};
  padding-bottom: ${(props) => (props.showLabel ? '24px' : '56px')};
  background: ${(props) => props.theme.background};
  border-radius: 0px 0px 4px 4px;
  grid-column: ${(props) => gridColPosition(props.index, 3)};
  grid-row: ${(props) => gridRowPosition(props.index, 3) + 1};
  position: relative;
  @media ${mediumSmallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 2)};
    grid-row: ${(props) => gridRowPosition(props.index, 2) + 1};
  }
  @media ${smallQuery} {
    grid-column: ${(props) => gridColPosition(props.index, 1)};
    grid-row: ${(props) => gridRowPosition(props.index, 1) + 1};
  }
`;

export const GradientHeaderCutStyle = {
  bottom: 0,
  right: 0,
  display: 'block',
  marginBottom: '-1px',
};

export const CardHeaderGradient = styled.div`
  margin: 0px -16px;
  @media ${smallQuery} {
    margin: 0px -16px;
  }
`;

export const GradientHeaderCut = (props: { className?: string }) => {
  return (
    <CardHeaderGradient className={props.className}>
      <svg
        style={GradientHeaderCutStyle}
        viewBox="0 0 848 104"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M848 103.562L848 0C848 0 772.5 16.0616 474.897 30.955C443.488 32.5268 412.038 33.8763 381.108 35.2034C191.061 43.3576 20.6407 50.6698 0 103.562H848Z"
          fill="white"
        />
      </svg>
    </CardHeaderGradient>
  );
};

export const CardContentContainer = styled.div`
  display: grid;
  row-gap: 24px;
  padding: 16px 32px;
  margin-bottom: 32px;
`;

export const CardContent = styled.div`
  display: grid;
  padding-top: 24px;
  border-top: 1px solid #98b8d9;
  &:first-child {
    border-top: none;
  }
`;

export const ThumbsUpRoot = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

const ActionInfoLabel = styled.div<{ current: boolean; standAlone: boolean }>`
  display: grid;
  height: 32px;
  align-items: center;
  column-gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
  grid-template-columns: min-content auto;
  background: ${(props) =>
    props.current ? props.theme.textAccent2 : props.theme.svgGreenHover};
  border-radius: ${(props) => (props.standAlone ? '20px' : '0px 0px 4px 4px')};
  color: ${(props) => (props.current ? '#fff' : props.theme.textAccent)};
  white-space: nowrap;
  ${(props) => {
    if (!props.standAlone) {
      return `
        position: absolute;
        bottom: -32px;
        width: 100%;
        z-index: 101;
      `;
    }
    return;
  }};
`;

export const ActionInfo = (props: {
  current?: boolean;
  standAlone?: boolean;
  date?: string;
}) => {
  return (
    <ActionInfoLabel
      current={props.current || false}
      standAlone={props.standAlone || false}
    >
      {props.current ? (
        <Play weight="bold" size={18} />
      ) : (
        <ClockCounterClockwise weight="bold" size={18} />
      )}
      <TextS
        accent={!props.current}
        style={props.current ? { color: '#fff' } : {}}
      >
        {props.current
          ? 'In progress'
          : `Implemented ${readableDate(props.date || '')}`}
      </TextS>
    </ActionInfoLabel>
  );
};

export const ThumbsUpButton = (props: {
  count: number;
  onClick?: () => void;
  selected?: boolean;
}) => {
  const theme = useTheme();
  const getStyle = () => {
    if (props.selected) {
      if (!props.onClick) {
        return {
          font: theme.textLight,
          background: theme.primaryButtonBackgroundDisabled,
        };
      } else {
        return {
          font: theme.textAccent,
          background: theme.gradient3,
        };
      }
    } else {
      if (!props.onClick) {
        return {
          font: theme.textLight,
          background: theme.background,
        };
      } else {
        return {
          font: theme.textAccent,
          background: theme.background,
        };
      }
    }
  };
  const styleObject = getStyle();
  return (
    <ThumbsUpRoot>
      <CircleButton
        onClick={props.onClick}
        size="48px"
        selected={props.selected}
        background={styleObject.background}
        disabled={!props.onClick}
      >
        <ThumbsUp size="24px" color={styleObject.font} weight="bold" />
      </CircleButton>
      <TextM
        accent={props.onClick !== undefined}
        light={!props.onClick}
        center
        style={{ visibility: props.count === 0 ? 'hidden' : 'visible' }}
      >
        {props.count}
      </TextM>
    </ThumbsUpRoot>
  );
};

export const StrengthCardContent = styled(CardContent)`
  grid-template-columns: min-content auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
`;

export const StrengthCard = (props: {
  title: string;
  votesCount: number;
  comments: string[];
  likes: VoteLikeItem[];
  currentUser: string;
  likeClick: (data: string) => void;
}) => {
  const [expand, setExpand] = useState(false);
  const shouldExpand = expand && props.comments.length > 0;
  return (
    <CardRoot id="test">
      <LeftRightIconCardHeader onClick={() => setExpand(!expand)}>
        <SvgButton
          type="star"
          buttonStyle={SvgButtonStyle.SolidGreen}
          text={props.votesCount + ''}
          size={42}
          selected
          disableShadow
        ></SvgButton>
        <Heading2>{props.title}</Heading2>
        {props.comments.length > 0 && (
          <IconButton text={props.comments.length + ''}>
            <ChatCircle size="30px"></ChatCircle>
          </IconButton>
        )}
      </LeftRightIconCardHeader>
      {shouldExpand && (
        <CardContentContainer>
          {props.comments.map((c, idx) => {
            return (
              <StrengthCardContent key={`${idx}-s-comment-${c}`}>
                <ThumbsUpButton
                  selected={
                    props.likes
                      ?.filter((likeItem) => likeItem.data === c)
                      .find((likeItem) =>
                        likeItem.users.find((id) => id === props.currentUser)
                      ) !== undefined
                  }
                  count={
                    props.likes.find((likeItem) => likeItem.data === c)?.users
                      .length || 0
                  }
                  onClick={() => props.likeClick(c)}
                />
                <TextM>{c}</TextM>
              </StrengthCardContent>
            );
          })}
        </CardContentContainer>
      )}
    </CardRoot>
  );
};

export const VoteCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30.66%);
  grid-column-gap: 4%;
  @media ${mediumSmallQuery} {
    grid-template-columns: repeat(2, 47.5%);
    grid-column-gap: 5%;
  }
  @media ${smallQuery} {
    grid-template-columns: 100%;
  }
`;

export const VoteCardHeaderTitle = styled(Heading1)`
  font-size: 24px;
`;

export const VoteCardHeader = (props: {
  index: number;
  title: string;
  votesCount: number;
  secondaryTitle: string;
}) => {
  return (
    <PositionedGradientHeader index={props.index}>
      <GradientHeaderContent>
        <SvgButton
          type="circle"
          buttonStyle={SvgButtonStyle.SolidBlue}
          text={props.votesCount.toString()}
          selected
          disableShadow
          size={40}
        ></SvgButton>
        <VoteCardHeaderTitle>{props.title}</VoteCardHeaderTitle>
        <TextB>{props.secondaryTitle}</TextB>
      </GradientHeaderContent>
      <GradientHeaderCut></GradientHeaderCut>
    </PositionedGradientHeader>
  );
};

export const VoteCardContent = (props: {
  index: number;
  text: string;
  likes: string[];
  currentUser: string;
  viewClick: () => void;
  likeClick?: () => void;
  children?: JSX.Element;
}) => {
  return (
    <VoteCardContentWrapper
      index={props.index}
      showLabel={props.children !== undefined}
    >
      <TextM>{props.text}</TextM>
      <ThumbsUpButton
        selected={
          props.likes?.find((id) => id === props.currentUser) !== undefined
        }
        count={props.likes.length || 0}
        onClick={props.likeClick}
      />
      <PrimaryButton onClick={props.viewClick}>View This Action</PrimaryButton>
      {props.children}
    </VoteCardContentWrapper>
  );
};

export const VoteGridItem = (props: {
  index: number;
  title: string;
  votesCount: number;
  secondaryTitle: string;
  text: string;
  likes: string[];
  currentUser: string;
  viewClick: () => void;
  likeClick?: () => void;
  currentAction: boolean;
  lastImplemented: string;
}) => {
  return (
    <React.Fragment>
      <VoteCardHeader
        index={props.index}
        title={props.title}
        votesCount={props.votesCount}
        secondaryTitle={props.secondaryTitle}
      ></VoteCardHeader>
      <VoteCardContent
        index={props.index}
        text={props.text}
        currentUser={props.currentUser}
        likes={props.likes}
        viewClick={props.viewClick}
        likeClick={props.likeClick}
      >
        {props.currentAction || props.lastImplemented !== '' ? (
          <ActionInfo
            current={props.currentAction}
            date={props.lastImplemented}
          ></ActionInfo>
        ) : undefined}
      </VoteCardContent>

      <VoteCardBoxShadow index={props.index}></VoteCardBoxShadow>
    </React.Fragment>
  );
};

const ProgressRoot = styled.div<{ percentage: number }>`
  display: grid;
  margin: 0px -16px;
  grid-template-columns: ${(props) => props.percentage}% auto;
  grid-template-rows: 24px;
  justify-self: stretch;
`;

const ProgressGreen = styled.div`
  background: ${(props) => props.theme.svgSolidGree};
`;

const ProgressWhite = styled.div`
  background: ${(props) => props.theme.background};
`;

export const CardProgress = (props: {
  percentage: number;
  className?: string;
}) => {
  return (
    <ProgressRoot percentage={props.percentage} className={props.className}>
      <ProgressGreen />
      <ProgressWhite />
    </ProgressRoot>
  );
};

const LoadingBase = styled.div`
  display: grid;
  margin-top: 20%;
  justify-content: center;
  align-items: center;
`;

export const GenericLoading = (props: { className?: string }) => {
  return (
    <LoadingBase className={props.className}>
      <CircleNotch size={64} color="#49A2FB">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 0 0"
          to="360 0 0"
          repeatCount="indefinite"
        ></animateTransform>
      </CircleNotch>
    </LoadingBase>
  );
};
