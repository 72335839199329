import styled from '../helpers/styled';

export interface CircleButtonProps {
  readonly background?: string;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  readonly size: string;
}

export const CircleButton = styled.button<CircleButtonProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: ${(props) => props.background};
  background-blend-mode: ${(props) => (props.selected ? 'multiply' : '')};
  border-radius: 50%;
  text-align: center;
  //line-height: 3rem;
  text-decoration: none;
  border: none;
  vertical-align: 'center';
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  ${(props) =>
    props.selected && `box-shadow: inset 0px 4px 4px rgba(28, 83, 153, 0.25);`}

  ${(props) =>
    props.size &&
    `
    width: ${props.size};
    height: ${props.size};
    padding: 0;
  `};

  ${(props) => {
    if (!props.disabled) {
      return `
      &:hover {
    background: ${props.theme.gradient3};
  }
      `;
    }
  }}

  ${(props) => props.disabled && `pointer-events: none;`}
  padding: 12px;
`;

export const SmileyButton = styled.button<CircleButtonProps>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: #ffffff;
  background-blend-mode: ${(props) => (props.selected ? 'multiply' : '')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //line-height: 3rem;
  color: #585a68;
  text-decoration: none;
  border: none;

  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  ${(props) =>
    props.selected &&
    `box-shadow: inset 0px 4px 4px rgba(28, 83, 153, 0.25);;
    background: linear-gradient(149.68deg, #E1F4FF 25.39%, #DBFFE3 84%);`}

  ${(props) =>
    props.size &&
    `
    width: ${props.size};
    height: ${props.size};
    padding: 0;
  `}

  &:hover {
    background: #e1e8ff;
    background-blend-mode: multiply;
  }

  ${(props) => props.disabled && `pointer-events: none;`}
`;

export const CheckButton = styled(SmileyButton)`
  background: ${(props) => props.theme.blueIconOnBlue};
  box-shadow: none;
  ${(props) =>
    props.selected &&
    `box-shadow: inset 0px 4px 4px rgba(28, 83, 153, 0.25);
    background: ${props.theme.result1};`}

  ${(props) =>
    props.size &&
    `
    width: ${props.size};
    height: ${props.size};
    padding: 0;
  `}
  &:hover {
    background: ${(props) => props.theme.result1};
    background-blend-mode: multiply;
  }
`;
