import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Split from './Split';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <Split />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Connect HMR
//TODO: add HMR for changing stores
/* if (module.hot) {
  module.hot.accept(["./stores/surveyStore"], () => {
      // Store definition changed, recreate a new one from old state
      renderApp(App, createTodoStore(getSnapshot(store)))
  })

} */
